import React, { useState, useEffect } from 'react';
import Select, { ValueType } from 'react-select';
import { withCookies, ReactCookieProps } from 'react-cookie';

import { languagesListConfig, GOOGLE_TRANSLATE_COOKIE_NAME } from './constants';
import {
  doTranslate,
  loadTranslationsScript,
  removeTranslationsScript,
  reloadPageOnCookieUpdate,
} from './helpers';

import { LanguageSelectStyles } from './LanguageSelectStyles';
import styles from './LanguageTranslator.module.css';

import { ILanguageListItem } from './types';

function LanguageTranslator(props: ReactCookieProps): React.ReactElement {
  const [languageItem, setLanguageItem] = useState<ILanguageListItem>(languagesListConfig[4]);

  useEffect(() => {
    const { cookies } = props;

    const isCookiesExist = Boolean(cookies?.get(GOOGLE_TRANSLATE_COOKIE_NAME));

    if (!isCookiesExist) {
      cookies?.set(GOOGLE_TRANSLATE_COOKIE_NAME, '/auto/de');
    }

    const script = loadTranslationsScript({ cookies, setLanguageItem });

    return () => removeTranslationsScript(script);
  }, [props.cookies]);

  const handleLanguageChange = (item: ValueType<ILanguageListItem>): void => {
    const castedOptionItem = item as ILanguageListItem;

    if (castedOptionItem.value !== languageItem.value) {
      const { cookies } = props;
      const staleGoogleTranslateCookie = cookies?.get(GOOGLE_TRANSLATE_COOKIE_NAME);

      doTranslate(castedOptionItem.value);
      setLanguageItem(castedOptionItem);

      reloadPageOnCookieUpdate({ cookies, staleGoogleTranslateCookie });
    }
  };

  return (
    <div className={styles.languageTranslatorContainer}>
      <div id="google_translate_element2" />
      <div className="notranslate">
        <Select<ILanguageListItem>
          styles={LanguageSelectStyles}
          value={languageItem}
          onChange={handleLanguageChange}
          options={languagesListConfig}
          isSearchable={false}
        />
      </div>
    </div>
  );
}

export default withCookies(LanguageTranslator);

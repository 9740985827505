export const translationExceptions: any = {
  '/auto/de': {
    Rail: 'Bahn',
    'Airports & Suppliers': 'Flughafen & Anbieter',
    'Airport & Travel Supplier Policies': 'Flughafen & Anbieter',
    'Travel news': 'Reise Nachrichten',
    'Country entry restrictions': 'Länder Einreisebeschränkungen',
    'Supplier list is subject to change': 'Die Liste der Dienstleister kann sich jederzeit ändern',
    'At Get Going we’re committed to help you make informed, confident and safe travel decisions.':
      // eslint-disable-next-line max-len
      'Bei GetGoing möchten wir Ihnen dabei helfen, fundierte, selbstbewusste und sichere Reiseentscheidungen zu treffen',
  },
  '/auto/fr': {
    // eslint-disable-next-line quotes
    Europe: "L'Europe",
    Low: 'Faible',
  },
  '/auto/es': {
    MENA: 'MONA',
  },
};

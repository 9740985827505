/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable max-len */
import React from 'react';
import styles from './Footer.module.css';

function Footer(): React.ReactElement {
  return (
    <div className={styles.footer}>
      <div className={styles.disclaimer}>
        TRAVEL INFORMATION PROVIDED BY GETGOING / BCD TRAVEL SHOULD NOT BE RELIED UPON AS LEGAL OR
        MEDICAL ADVICE. TRAVELERS SHOULD VISIT THE WORLD HEALTH ORGANIZATION OR LOCAL SITES FOR THE
        LATEST MOST ACCURATE INFORMATION ON SPECIFIC TRAVEL REQUIREMENTS AND RESTRICTIONS. BCD
        TRAVEL DISCLAIMS ANY AND ALL LIABILITY FOR ANY LOSS RESULTING FROM RELIANCE ON SUCH
        INFORMATION.
      </div>
      <div className={styles.corpInfo}>
        © {new Date().getFullYear()} BCD Travel, All rights reserved. GetGoing© and BCD Travel® are
        registered trademarks of BCD Group.
      </div>
      <div className={styles.linksContainer}>
        <div className={styles.linkContainer}>
          <a
            className={styles.link}
            href="https://www.bcdtravel.com/privacy-policy/"
            target="_blank"
            rel="noreferrer"
          >
            Privacy policy
          </a>
        </div>
        <div className={styles.linkContainer}>
          <a
            className={styles.link}
            href="https://www.bcdtravel.com/legal/"
            target="_blank"
            rel="noreferrer"
          >
            Terms and conditions
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;

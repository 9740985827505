import React from 'react';
import { ReactCookieProps, withCookies } from 'react-cookie';

import { GOOGLE_TRANSLATE_COOKIE_NAME } from '@components/LanguageTranslator/constants';
import { getTranstlatedText } from 'helpers/getTranslatedText';

import { useScreenMatchOnMobile } from '@hooks/useScreenMatchOnMobile';
import DesktopPlaneIcon from '../../../../static/plane_image.svg';

import styles from './Banner.module.css';

const Banner = (props: ReactCookieProps): React.ReactElement => {
  const { cookies } = props;

  const lang = cookies?.get(GOOGLE_TRANSLATE_COOKIE_NAME);

  const currentText =
    'At GetGoing we’re committed to help you make informed, confident and safe travel decisions.';

  const isMobile = useScreenMatchOnMobile();

  return (
    <div className={styles.bannerWrapper}>
      <div className={styles.bannerContainer}>
        <div className={styles.banner}>
          <h1>COVID-19 {!isMobile ? 'Information' : 'Info'} Hub </h1>

          <h2 className={styles.subtitle}>{getTranstlatedText(currentText, lang)}</h2>
        </div>

        <div className={styles.icon}>
          <img src={DesktopPlaneIcon} className={styles.iconImage} alt="plane" />
        </div>
      </div>
    </div>
  );
};

export default withCookies(Banner);

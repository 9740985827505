import React from 'react';

import LanguageTranslator from '@components/LanguageTranslator';

import { useScreenMatchOnMobile } from '@hooks/useScreenMatchOnMobile';

import GetGoingLogo from '../../../../static/getgoing_logo.svg';

import styles from './Header.module.css';

interface Props {
  disableHeaderLink?: boolean;
}

function Header(): React.ReactElement {
  const isMobile = useScreenMatchOnMobile();

  const linkToTheGetGoing = 'https://www.getgoing.com/';

  return (
    <div className={styles.header}>
      <div className={styles.headerContainer}>
        <div className={styles.logoContainer}>
          <a target="_blank" rel="noopener noreferrer" href={linkToTheGetGoing}>
            <img className={styles.logo} src={GetGoingLogo} alt="Site logo" />
          </a>

          {!!isMobile && <div className={styles.logoLine}> </div>}
        </div>

        <div className={styles.langContainer}>
          <LanguageTranslator />
        </div>
      </div>
    </div>
  );
}

export default Header;

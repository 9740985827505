import { Dispatch, SetStateAction } from 'react';
import { Cookies } from 'react-cookie';
import { ILanguageListItem } from './types';

import { languagesListConfig, GOOGLE_TRANSLATE_COOKIE_NAME, scriptSrc } from './constants';

export const getLanguageItemByCode = (value: string): ILanguageListItem => {
  const code = value.split('/').slice(1).join('|');

  return languagesListConfig.find(langData => langData.value === code) || languagesListConfig[4];
};

export const loadTranslationsScript = ({
  cookies,
  setLanguageItem,
}: {
  cookies?: Cookies;
  setLanguageItem: Dispatch<SetStateAction<ILanguageListItem>>;
}) => {
  const languageCode = cookies?.get(GOOGLE_TRANSLATE_COOKIE_NAME);
  const script = document.createElement('script');
  script.src = scriptSrc;
  script.id = 'translate_google';

  document.head.appendChild(script);

  script.onload = () => {
    if (languageCode) {
      setLanguageItem(getLanguageItemByCode(languageCode));
    }
  };

  return script;
};

export const removeTranslationsScript = (script: HTMLElement): void => {
  document.head.removeChild(script);
};

function GTranslateFireEvent(langSelect: HTMLSelectElement | undefined, eventName: string) {
  try {
    const event = document.createEvent('HTMLEvents');

    event.initEvent(eventName, true, true);
    langSelect?.dispatchEvent(event);
  } catch (e) {
    console.log('we can not translate this site because', e);
  }
}

export function doTranslate(langPair: string | { value: string }) {
  if (typeof langPair === 'object' && langPair.value) langPair = langPair.value;

  if (langPair === '') return;

  let lang = '';

  if (typeof langPair === 'string') {
    [, lang] = langPair.split('|');
  }

  const langSelect = document.querySelector('select.goog-te-combo') as HTMLSelectElement;
  const scriptNode = document.getElementById('google_translate_element2');

  if (
    scriptNode == null ||
    scriptNode.innerHTML.length == 0 ||
    langSelect?.length == 0 ||
    langSelect?.innerHTML?.length == 0
  ) {
    setTimeout(() => {
      doTranslate(lang);
    }, 500);
  } else {
    if (typeof langSelect === 'object') {
      langSelect.value = lang;
    }

    GTranslateFireEvent(langSelect, 'change');
  }
}

export function reloadPageOnCookieUpdate({
  cookies,
  staleGoogleTranslateCookie,
}: {
  cookies?: Cookies;
  staleGoogleTranslateCookie: string;
}): void {
  const newCookie = cookies?.get(GOOGLE_TRANSLATE_COOKIE_NAME);

  if (staleGoogleTranslateCookie === newCookie) {
    setTimeout(() => {
      reloadPageOnCookieUpdate({ cookies, staleGoogleTranslateCookie });
    }, 0);
  } else {
    // Remove cookie to prevent google translation requests if default site language
    if (newCookie === '/auto|de') cookies?.remove(GOOGLE_TRANSLATE_COOKIE_NAME);

    window.location.reload();
  }
}
